import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [// {
//   name: 'menu.dashboard',
//   key: 'dashboard',
//   children: [
//     {
//       name: 'menu.dashboard.workplace',
//       key: 'dashboard/workplace',
//     },
//   ],
// },
{
  name: 'Home',
  key: 'home'
}, {
  name: 'standings',
  key: 'standings'
}, {
  name: 'tv',
  key: 'tv'
}, {
  name: 'soccer',
  key: 'soccer'
}, {
  name: 'app',
  key: 'app'
}, {
  name: 'about',
  key: 'about'
}, {
  name: 'Pro',
  key: 'pro',
  children: [{
    name: 'Season',
    key: 'pro/archive/season',
    breadcrumb: true
  }, {
    name: 'Archive',
    key: 'pro/archive',
    breadcrumb: true
  }, {
    name: 'WRC',
    key: 'pro/wrc',
    breadcrumb: true
  }, {
    name: 'Others',
    key: 'pro/others',
    breadcrumb: true
  }, {
    name: 'Eurosport',
    key: 'pro/eurosport',
    breadcrumb: true
  }, {
    name: 'Starplus',
    key: 'pro/starplus',
    breadcrumb: true
  }, {
    name: 'StarPlusSeries',
    key: 'pro/starplusseries',
    breadcrumb: true
  }, {
    name: 'Pro',
    key: 'pro'
  }]
}, {
  name: 'shows',
  key: 'shows'
}, {
  name: 'info',
  key: 'info'
}, {
  name: 'F1',
  key: 'f1'
}, {
  name: 'Player',
  key: 'player',
  children: [{
    name: 'player',
    key: 'player'
  }]
}, {
  name: 'multiplePlayer',
  key: 'multiplePlayer'
}, {
  name: 'Token',
  key: 'token'
}, {
  name: 'callback',
  key: 'callback'
}, {
  name: 'Reset',
  key: 'reset'
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;

    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }

    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;

      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }

      if (!visible) {
        continue;
      }

      if (route.children && route.children.length) {
        const newRoute = { ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);

        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route
        });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [userPermission]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];

    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }

    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};

export default useRoute;