const i18n = {
  'en-US': {
    'login.form.title': 'Login to Hi Streams',
    'login.form.email.errMsg': 'Email cannot be empty',
    'login.form.password.errMsg': 'Password cannot be empty',
    'login.form.login.errMsg': 'Login error, please refresh and try again',
    'login.form.email.placeholder': 'Email',
    'login.form.password.placeholder': 'Password',
    'login.form.rememberPassword': 'Remember password',
    'login.form.forgetPassword': 'Forgot password',
    'login.form.login': 'login',
    'login.form.register': 'register account',
    'login.form.reset.success': 'Success! Please check the reset email',
    'login.banner.slogan1': 'Out-of-the-box high-quality template',
    'login.banner.subSlogan1': 'Rich page templates, covering most typical business scenarios',
    'login.banner.slogan2': 'Built-in solutions to common problems',
    'login.banner.subSlogan2': 'Internationalization, routing configuration, state management everything',
    'login.banner.slogan3': 'Access visualization enhancement tool AUX',
    'login.banner.subSlogan3': 'Realize flexible block development'
  },
  'zh-CN': {
    'login.form.title': '登录 Hi Streams',
    'login.form.email.errMsg': '邮箱不能为空',
    'login.form.password.errMsg': '密码不能为空',
    'login.form.login.errMsg': '邮箱或密码不正确',
    'login.form.email.placeholder': '邮箱',
    'login.form.password.placeholder': '密码',
    'login.form.rememberPassword': '记住密码',
    'login.form.forgetPassword': '忘记密码',
    'login.form.login': '登录',
    'login.form.register': '注册账号',
    'login.form.reset.success': '发送成功,请检查邮箱的重置密码链接',
    'login.banner.slogan1': '开箱即用的高质量模板',
    'login.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
    'login.banner.slogan2': '内置了常见问题的解决方案',
    'login.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
    'login.banner.slogan3': '接入可视化增强工具AUX',
    'login.banner.subSlogan3': '实现灵活的区块式开发'
  }
};
export default i18n;