const i18n = {
  'en-US': {
    'register.form.title': 'Register to Hi Streams',
    'register.form.mail.errMsg': 'Email cannot be empty',
    'register.form.password.errMsg': 'Password cannot be empty',
    'register.form.confirmPassword.errMsg': 'Confirm Password cannot be empty',
    'register.form.confirmPassword.confirm.errMsg': 'Confirm password must be equal with password',
    'register.form.register.errMsg': 'register error, please refresh and try again',
    'register.form.code.errMsg': 'Invite code cannot be empty',
    'register.form.mail.placeholder': 'Email',
    'register.form.password.placeholder': 'Password',
    'register.form.confirmPassword.placeholder': 'Please confirm your password',
    'register.form.code.placeholder': 'Invite code',
    'register.form.register': 'Register',
    'register.form.modal.msg': 'YOU NEED TO BE IN F1TV PRO COUNTRY FOR THE STREAMS TO PLAY. Viaplay EU IP Required',
    'register.form.mail_code.errMsg': 'Verify code cannot be empty',
    'register.form.mail_code.placeholder': 'Verify code'
  },
  'zh-CN': {
    'register.form.title': '注册 Hi Streams',
    'register.form.mail.errMsg': '请填写邮箱',
    'register.form.password.errMsg': '密码不能为空',
    'register.form.confirmPassword.errMsg': '确认密码不能为空',
    'register.form.confirmPassword.confirm.errMsg': '确认密码不一致',
    'register.form.code.errMsg': '邀请码不能为空',
    'register.form.register.errMsg': '注册失败，请重试',
    'register.form.mail.placeholder': '电子邮箱',
    'register.form.password.placeholder': '密码',
    'register.form.confirmPassword.placeholder': '确认密码',
    'register.form.code.placeholder': '邀请码',
    'register.form.register': '注册',
    'register.form.modal.msg': '请确保自己的网络环境支持观看流媒体，F1TV Pro需要对应的国家地区，Viaplay需要欧洲IP',
    'register.form.mail_code.errMsg': '验证码不能为空',
    'register.form.mail_code.placeholder': '验证码'
  }
};
export default i18n;