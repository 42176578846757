const i18n = {
  'en-US': {
    home: 'Home',
    app: 'App',
    tv: 'TV',
    soccer: 'Soccer',
    standings: 'Standings',
    pro: 'Pro',
    about: 'About',
    'season.results': 'Results',
    'info.email': 'Email',
    'info.name': 'Name',
    'info.created': 'Created',
    'info.updated': 'Updated',
    'info.subscription': 'F1TV PRO Donate',
    'info.subscription1': '4K Donate',
    'info.subscription2': 'Elta Donate',
    'info.profile': 'Profile',
    'info.redeem': 'Redeem',
    'info.placeholder': 'Please enter redeem',
    'info.submit': 'Submit',
    'info.password': 'Password',
    'info.password1': 'Confirm Password',
    'info.password.same': 'Password inconsistency',
    'week.0': 'Sun',
    'week.1': 'Mon',
    'week.2': 'Tues',
    'week.3': 'Wed',
    'week.4': 'Thur',
    'week.5': 'Fri',
    'week.6': 'Sat',
    'home.countdown': 'D 天 H 时 m 分 s 秒',
    'home.live': 'WATCH LIVE!',
    'home.replay': 'replay',
    'soccer.watch': 'Watch',
    'soccer.intro': "This page is required to allow Chrome to load http links on this site., click on the 🔒 icon in the URL bar, then click 'Site settings'.There you will see a list of various permissions the page has. Choose 'Allow' next to 'Insecure content'.Then reload the page.",
    'menu.dashboard': 'Dashboard',
    'menu.dashboard.workplace': 'Workplace',
    'menu.user.info': 'User Info',
    'menu.user.setting': 'User Info',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeek': 'Color Week',
    'settings.alertContent': 'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message': 'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip': '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'SeeMore',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language switch to ',
    'navbar.search.placeholder': 'Please search'
  },
  'zh-CN': {
    home: '首页',
    standings: '积分榜',
    tv: 'TV',
    soccer: '足球',
    app: 'App',
    pro: 'PRO',
    about: '关于',
    'season.results': '结果',
    'info.email': '邮箱',
    'info.name': '昵称',
    'info.created': '注册日期',
    'info.subscription': 'F1TV PRO捐赠',
    'info.subscription1': '4K频道捐赠',
    'info.subscription2': '愛爾達频道捐赠',
    'info.updated': '更新日期',
    'info.profile': '个人资料',
    'info.redeem': '兑换码',
    'info.placeholder': '请输入兑换码',
    'info.submit': '提交',
    'info.password': '新密码',
    'info.password1': '重复密码',
    'info.password.same': '密码不一致',
    'week.0': '周日',
    'week.1': '周一',
    'week.2': '周二',
    'week.3': '周三',
    'week.4': '周四',
    'week.5': '周五',
    'week.6': '周六',
    'home.countdown': 'D 天 H 时 m 分 s 秒',
    'home.live': '观看直播!',
    'home.replay': '录像',
    'soccer.watch': '观看直播',
    'soccer.intro': '本页面需要要允许 Chrome 加载本网站上的http链接，请单击 URL 栏中左侧的🔒图标，然后单击“网站设置”。列表中找到“不安全内容”，选择“允许”。重新加载本页面。',
    'menu.dashboard': '仪表盘',
    'menu.dashboard.workplace': '工作台',
    'menu.user.info': '用户信息',
    'menu.user.setting': '用户信息',
    'menu.user.switchRoles': '切换角色',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'navbar.logout': '退出登录',
    'settings.title': '页面配置',
    'settings.themeColor': '主题色',
    'settings.content': '内容区域',
    'settings.navbar': '导航栏',
    'settings.menuWidth': '菜单宽度 (px)',
    'settings.navbar.theme.toLight': '点击切换为亮色模式',
    'settings.navbar.theme.toDark': '点击切换为暗黑模式',
    'settings.menu': '菜单栏',
    'settings.footer': '底部',
    'settings.otherSettings': '其他设置',
    'settings.colorWeek': '色弱模式',
    'settings.alertContent': '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    'settings.copySettings': '复制配置',
    'settings.copySettings.message': '复制成功，请粘贴到 src/settings.json 文件中',
    'settings.close': '关闭',
    'settings.color.tooltip': '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
    'navbar.search.placeholder': '输入内容查询'
  }
};
export default i18n;